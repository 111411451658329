/* eslint-disable */
<template>
    <v-container class="text-caption " fluid>
  <v-row justify="center" class="text-caption " fluid>

      <v-card fluid style="width:95%; height:90dvh;">
        <v-toolbar  class="rounded-t"    color="#05445E"  >
          <template v-slot:image>
            <v-img
                        src="./fond_vague_long.png"
                        class="align-end"
                        gradient="to right, #05445EDD, #05445EFF"
                        cover
                      ></v-img></template>
          <v-toolbar-title style="color:white">Client {{client.nomNaissance}} {{client.prenoms[0]}} {{client.prenoms[1]}} {{client.prenoms[2]}} {{client.prenoms[3]}} {{client.prenoms[4]}} {{client.prenoms[5]}}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn color="white" @click="  back()" icon="mdi:mdi-close" >  </v-btn>
         
        </v-toolbar>
     <!--   {{client.nomNaissance}} {{client.prenoms[0]}} {{client.prenoms[1]}} {{client.prenoms[2]}} {{client.prenoms[3]}} {{client.prenoms[4]}} {{client.prenoms[5]}} né(e) le {{client.naissance}} - <a :class="[ jourPersonnel.couleur]"  >  J.P. : {{ jourPersonnel.valeur }}</a> - <div :class="[ moisPersonnel.couleur]"  >  M.P. : {{ moisPersonnel.valeur }}</div> -  <div :class="[ anneePersonnelle.couleur]"  >  A.P. : {{ anneePersonnelle.valeur }}</div>-->
       

        <v-tabs v-model="tab" class="rounded-t-xl"  >
          <v-tab
            v-for="item in items"
            :key="item"
            :value="item"
          >
            {{ item }}
          </v-tab>

        </v-tabs>
        <v-window v-model="tab" style="overflow: auto!important;">
              <v-window-item key="Informations" value="Informations">
              
           <v-sheet
           style="height:70dvh;width:95%;">
        <v-form v-model="valid" ref="form" >
          <v-row class="ma-4">
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="client.nom"
                        label="Nom"
                        :rules="requiredRules"
                        required
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="client.nomNaissance"
                        label="Nom de Naissance"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
          <v-row class="ma-4">
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                      
                        v-model="client.prenoms[0]"
                        label="1er Prénom"
                        required
                        :rules="requiredRules"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                      
                        v-model="client.prenoms[1]"
                        label="2ème Prénom"
            
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                      
                        v-model="client.prenoms[2]"
                        label="3ème Prénom"
                  
                        hide-details
                      ></v-text-field>
                    </v-col>             </v-row>
                    <v-row class="ma-4">
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                      
                        v-model="client.prenoms[3]"
                        label="4ème Prénom"
                    
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                      
                        v-model="client.prenoms[4]"
                        label="5ème Prénom"
                   
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                      
                        v-model="client.prenoms[5]"
                        label="6ème Prénom"
                    
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                
               
                    <v-row class="ma-4">
                      <v-col cols="12" md="4" class="pa-1">
                        <v-text-field
                          v-model="client.naissance"
                          label="Date de naissance"
                          type="date"
                          hide-details
                          :rules="requiredRules"
                          required
                        ></v-text-field>
                      </v-col>
                   
                    <v-col class="pa-1" cols="12" md="4">
                      <v-text-field
                        v-model="client.mail"
                        label="E-mail"
                        hide-details
                   
                      ></v-text-field>
                    </v-col>



                    <v-col cols="12" md="4" class="pa-1">
                      <v-text-field
                        v-model="client.telephone"
                        label="Téléphone"
                        hide-details
                     
                      
                      ></v-text-field>
                    </v-col>
                  
                  </v-row>
                <v-row class="ma-4">
                  <v-col cols="12" class="pa-1">
                    <v-textarea
                      v-model="client.adresse"
                      label="Adresse"
                      hide-details
                      no-resize
                    ></v-textarea>
                  </v-col>
                
                </v-row>
           
<v-row>
</v-row><v-row>
 
 <v-col  cols="12"> 
  &nbsp; <v-btn  prepend-icon="mdi:mdi-content-save"    color="#05445E"  variant="flat"  class="text-white " 
 @click="save"
 >
 Enregistrer
 </v-btn> &nbsp;
  <v-btn prepend-icon="mdi:mdi-delete-forever"   @click="supprimer" color="red-lighten-1"    variant="flat"> Supprimer le client</v-btn> </v-col>

</v-row>
    
        </v-form>
      
        <br/>  <br/>
      </v-sheet>
        </v-window-item>
        <v-window-item key="Notes" value="Notes">
          <v-container  fluid    style="height:80dvh; ">
            <br/> 
                <v-row class="ma-4">
                  <v-col cols="12" >  
            <v-btn @click="ajouterNote">Ajouter une note</v-btn>  
          </v-col>
                </v-row>
                <v-row class="ma-4">
                  <v-col cols="12"     v-for="item in notes"
                  :key="item">  
            
    
                    <div>
                      <h2 :class="`mt-n1 headline font-weight-light mb-4 `">
                        <v-tooltip text="Modifier la note" class="text-caption">
                          <template v-slot:activator="{ props }">   
       
                          <v-btn @click="ouvrir(item.id)"  v-bind="props" icon> <v-icon  size="small">mdi:mdi-pencil</v-icon></v-btn> 
                          </template>
                        </v-tooltip>        {{(new Date(item.createdAt)).getDate() }}/{{((new Date(item.createdAt)).getMonth()+1).toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                          useGrouping: false
                        }) }}/{{(new Date(item.createdAt)).getFullYear() }}
                      </h2>
                    
                        <div   class="content ql-editor" v-html="item.content"> </div>
                 
                    </div>
                  </v-col>
                </v-row>
    
           <br/>
         </v-container>
        </v-window-item>
        <v-window-item key="Thème" value="Thème">
          <div    style="height:70dvh;">
         
          <v-sheet id="toPrint" width="800" height="1200px" style=" margin: auto;" >
            <br/>
            <v-row> 
                 <v-col cols="6"  style="padding-top:0">  
          <ThemePlan :planVie="planVie" showComment="false"></ThemePlan>
   
        </v-col>
        <v-col cols="6"  style="padding-top:0">
          <ThemeCycle :cycleNaissance="cycleNaissance"></ThemeCycle>
          <ThemeChampExperience :champExperiences="champExperiences"></ThemeChampExperience>
        </v-col> 
      </v-row>  
 
        <v-row >    <v-col cols="6" style="padding-top:0; padding-left:10px">  
          <ThemeDoubleSpiral style="margin-right: -30px;" :doubleSpiral="doubleSpiral"></ThemeDoubleSpiral>
          </v-col>
          <v-col cols="6"  style="padding-top:0  ;  margin-left: -15px">  
            <ThemeDoubleSpiralDroite style="margin-left: -30px;" :doubleSpiral="doubleSpiral"></ThemeDoubleSpiralDroite> </v-col>
            
      </v-row> 
      <v-row >    <ThemePlanExpression :planExpression="planExpression"></ThemePlanExpression>
      </v-row> 
          </v-sheet>
          <br/>
          <br/>
          <br/> <br/>
        </div>
        </v-window-item>
        
        <v-window-item key="Calendrier" value="Calendrier">
          <v-container  fluid    style="height:68dvh; overflow: scroll;"> <v-text-field
          v-model="anneeEtude"
          type="number"
          label="Saisir une année"
          
          hide-details
      v-on:change="refresh"
          required
        ></v-text-field>  
        <div    style="height:68dvh;">
         
          <v-sheet width="1200px" style=" margin: auto;" > 
            <CalendrierComponent :clientId="client.id" :dateetude="anneeEtude">    </CalendrierComponent>
            <JourEnergieComponent :clientId="client.id" :dateetude="anneeEtude">         </JourEnergieComponent>
      </v-sheet> </div>
     </v-container>    
     </v-window-item>
        <v-window-item key="Cycle 9 ans" value="Cycle 9 ans"> 
          
          <v-container  fluid    style="height:75dvh; overflow: scroll;" >
            <v-text-field
          v-model="anneeEtude"
          label="Saisir une année"
          type="number"
          hide-details
      v-on:change="refresh"
          required
        ></v-text-field> 
        <div style="height:75dvh;">
        <v-sheet width="1200px" style=" margin: auto;" > 
           <CycleComponent :clientId="client.id" :dateetude="anneeEtude"  style=" margin: auto;"></CycleComponent>  
        </v-sheet>
      </div> </v-container> 
     </v-window-item>
        <v-window-item key="Plan de l'année" value="Plan de l'année">
          <v-container  fluid    style="height:68dvh; overflow: scroll;">  <v-text-field
                 v-model="anneeEtude"
                 type="number"
                 label="Saisir une année"
       
          hide-details
      v-on:change="refresh"
          required
        ></v-text-field>   
        <div style="height:68dvh;">
          <v-sheet width="1200px" style=" margin: auto;" > 
             <PlanComponent :clientId="client.id" :dateetude="anneeEtude"></PlanComponent>   </v-sheet>
            </div>   </v-container></v-window-item>
        <v-window-item key="Créer une étude" value="Créer une étude">
          <v-container  fluid   >
              <v-row> 
            <v-col class="d-none d-lg-flex " cols="2">
              <v-card style="height: 85%;    ">
                <v-card-title class="text-h5">
                  <v-text-field
                  v-model="dateetude"
                  label="Date de l'étude"
                type="date"
                  hide-details
              v-on:change="refresh"
                  required
                ></v-text-field>
                <v-btn style="width:100%"    @click="newEtude">
                  Créer une étude
                </v-btn></v-card-title>
                  <v-list selectable activable  class="v-list-etude"  style="overflow: scroll;">
                  
                    <v-list-item
                    active-color="blue"
                    :active="crtEtude==etude"
                    v-for="etude in etudes"
                    :key="etude"
                    v-on:click="crtdateetude=etude.dateEtude;
                    crtEtude=etude;
                    includeCalendrier=etude.includeCalendrier;
                    includePlan=etude.includePlan;
                    includeCycle=etude.includeCycle;
                    includePlanVie=etude.includePlanVie;
                    includeCycleNaissance=etude.includeCycleNaissance;
                    includeChampExperiences=etude.includeChampExperiences;
                    includePlanExpression=etude.includePlanExpression;
                    includeDoubleSpiral=etude.includeDoubleSpiral;
                    includeDoubleSpiralDroite=etude.includeDoubleSpiralDroite;
                    includeFullDoubleSpiral=etude.includeFullDoubleSpiral;
                    includeRecapTheme=etude.includeRecapTheme;
                    includePageGarde=etude.includePageGarde;
                    anneeCalendrier= etude.anneeCalendrier? etude.anneeCalendrier: (new Date(etude.dateEtude)).toISOString().substring(0,4);
                    anneePlan= etude.anneePlan? etude.anneePlan: (new Date(etude.dateEtude)).toISOString().substring(0,4);
                    anneeCycle= etude.anneeCycle? etude.anneeCycle: (new Date(etude.dateEtude)).toISOString().substring(0,4);
                      "
                    > {{(new Date(etude.dateEtude)).getDate() }}/{{((new Date(etude.dateEtude)).getMonth()+1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    }) }}/{{(new Date(etude.dateEtude)).getFullYear() }}  </v-list-item>
                  </v-list>
                </v-card>
              </v-col>
              <v-col cols="12" lg="10">
              <v-row>
                <v-btn  class="d-flex d-lg-none "  prepend-icon="mdi:mdi-dots-vertical">
                  Liste des études 
           
                 <v-menu activator="parent"  :close-on-content-click="false"     v-model="menu">
                   <v-card style="height: 90%;">
                     <v-card-title class="text-h5"> <v-text-field
                       v-model="dateetude"
                       label="Date de l'étude"
                     type="date"
                       hide-details
                   v-on:change="refresh"
                       required
                     ></v-text-field>
                     <v-btn style="width:100%"    @click="newEtude">
                       Créer
                     </v-btn></v-card-title>
                       <v-list selectable activable>
                       
                         <v-list-item
                         v-for="etude in etudes"
                         :key="etude"
                         v-on:click="ccrtdateetude=etude.dateEtude;
                         crtEtude=etude;
                         includeCalendrier=etude.includeCalendrier;
                         includePlan=etude.includePlan;
                         includeCycle=etude.includeCycle;
                         includePlanVie=etude.includePlanVie;
                         includeCycleNaissance=etude.includeCycleNaissance;
                         includeChampExperiences=etude.includeChampExperiences;
                         includePlanExpression=etude.includePlanExpression;
                         includeDoubleSpiral=etude.includeDoubleSpiral;
                         includeDoubleSpiralDroite=etude.includeDoubleSpiralDroite;
                         includeFullDoubleSpiral=etude.includeFullDoubleSpiral;
                         includeRecapTheme=etude.includeRecapTheme;
                         includePageGarde=etude.includePageGarde;
                         anneeCalendrier= etude.anneeCalendrier? etude.anneeCalendrier: (new Date(etude.dateEtude)).toISOString().substring(0,4);
                         anneePlan= etude.anneePlan? etude.anneePlan: (new Date(etude.dateEtude)).toISOString().substring(0,4);
                         anneeCycle= etude.anneeCycle? etude.anneeCycle: (new Date(etude.dateEtude)).toISOString().substring(0,4);
                         menu=false;"
                           
                         >  {{(new Date(etude.dateEtude)).getDate() }}/{{((new Date(etude.dateEtude)).getMonth()+1).toLocaleString('en-US', {
                           minimumIntegerDigits: 2,
                           useGrouping: false
                         }) }}/{{(new Date(etude.dateEtude)).getFullYear() }}</v-list-item>
                       </v-list>
                     </v-card>
                 </v-menu>
               </v-btn>
               <v-btn :disabled="!this.crtEtude || !this.crtEtude.dateEtude" @click="openConfig()"  prepend-icon="mdi:mdi-wrench"> Sélectionner des outils</v-btn> 
                <v-btn :disabled="!this.crtEtude || !this.crtEtude.dateEtude" @click="print()"  prepend-icon="mdi:mdi-printer">Imprimer l'étude</v-btn>
                <v-btn  :disabled="!this.crtEtude || !this.crtEtude.dateEtude" @click="showDelete=true"  prepend-icon="mdi:mdi-delete-forever"> Supprimer</v-btn> 
                <v-btn   @click="showHelp()"  prepend-icon="mdi:mdi-help">Aide</v-btn> 
              </v-row>
              <br/>
              <v-row v-if="crtEtude && crtEtude.dateEtude && crtEtude.id"   style="height:55dvh; overflow: scroll; margin-top:10px">  
                
                <EtudeDetailComponent :clientId="client.id" 
                :dateetude="crtdateetude"
                :etudeId="crtEtude.id"
                :client="client"
                :planVie="planVie"
                :cycleNaissance="cycleNaissance"
                :champExperiences="champExperiences"
                :planExpression="planExpression"
                :doubleSpiral="doubleSpiral"
                :includePlanVie="includePlanVie"
                :includeCycleNaissance="includeCycleNaissance"
                :includeChampExperiences="includeChampExperiences"
                :includePlanExpression="includePlanExpression"
                :includeDoubleSpiral="includeDoubleSpiral"
                :includeDoubleSpiralDroite="includeDoubleSpiralDroite"
                :includeFullDoubleSpiral="includeFullDoubleSpiral"
                :includePlan="includePlan"
                :includeCycle="includeCycle"
                :includeCalendrier="includeCalendrier"
                :includeRecapTheme="includeRecapTheme"
                :includePageGarde="includePageGarde"
                :anneeCalendrier="anneeCalendrier"
                :anneePlan="anneePlan"
                :anneeCycle="anneeCycle"
                :includeCommentaires="includeCommentaires"
                  ></EtudeDetailComponent>
                </v-row>
                <v-row v-else style="height:60dvh; overflow: scroll; margin-top:10px">
                  <v-col cols="12" class="text-center" >
                  <div class="text-center" > 
                    <v-icon size="50">mdi:mdi-information-outline</v-icon>
                    <p class="text-h6 text-left "  style="font-weight:bold;margin-top: 40px;">Les étapes pour créer une étude :</p>
                    <p class="text-h6 text-left" style="line-height: 3rem;">   <br/> -  Etape 1 : Choisissez la <b>"Date de l'étude"</b> puis cliquer sur <b>"Créer une étude"</b>
                     <br/> - Etape 2 : Dans <b>"Sélectionner des outils"</b>, choisissez les pages que vous souhaitez voir apparaître dans l'étude  
                     <br/> - Etape 3 : <b>Intégrez les commentaires</b> en cliquant directement sur les nombres dans le document
                     <br/> - Etape 4 : Cliquez sur <b>"Imprimer l'étude"</b> pour imprimer le document ou l'éditer en pdf. 
                    <br/> Précision : La mise en forme du document (marges, pied de page...) apparaît lorsque vous cliquez sur "imprimer une étude". </p>
                    </div>
                  </v-col>
                </v-row>  
                <v-row style="height:60dvh; overflow: scroll; margin-top:10px ;visibility:collapse">
                  <v-col cols="12" class="text-center" id="aide">
                  <div class="text-center" > 
                    <v-icon size="50">mdi:mdi-information-outline</v-icon>
                    <p class="text-h6 text-left "  style="font-weight:bold;margin-top: 40px;">Les étapes pour créer une étude :</p>
                    <p class="text-h6 text-left" style="line-height: 3rem;">   <br/> -  Etape 1 : Choisissez la <b>"Date de l'étude"</b> puis cliquer sur <b>"Créer une étude"</b>
                     <br/> - Etape 2 : Dans <b>"Sélectionner des outils"</b>, choisissez les pages que vous souhaitez voir apparaître dans l'étude  
                     <br/> - Etape 3 : <b>Intégrez les commentaires</b> en cliquant directement sur les nombres dans le document
                     <br/> - Etape 4 : Cliquez sur <b>"Imprimer l'étude"</b> pour imprimer le document ou l'éditer en pdf. 
                    <br/> Précision : La mise en forme du document (marges, pied de page...) apparaît lorsque vous cliquez sur "imprimer une étude". </p>
                    </div>
                  </v-col>
                </v-row>  
        </v-col>
              </v-row>  
    
          </v-container>
        </v-window-item>
        </v-window>
      </v-card>     
      
      <v-dialog v-model="showDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">
           Supprimer  l'étude.
          </v-card-title>
          <v-card-text>Êtes-vous sûr de vouloir supprimer cette étude ? Attention, cette opération est irréversible.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  color="blue darken-1" text @click="showDelete=false">Annuler</v-btn>
            <v-btn color="blue darken-1" text @click="deleteCurentEtude();showDelete=false">Valider</v-btn>
            <v-spacer></v-spacer>
         
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showConfig" max-width="800px">
        <v-card>
          <v-card-title class="text-h5">
            Quels outils voulez-vous voir ?
          </v-card-title>
          <v-row></v-row>
          <v-row>
            <v-col cols="4">
              <p style="color: grey; margin-left:12px;">Année du calendrier</p>
              <datePicker style="margin-left:12px;" 
              :placeholder="anneeCalendrier"
              v-model="anneeCalendrierFull"
               @changedYear="handleChangedYear"
              :minimum-view="'year'"
              :maximum-view="'year'"
               :typeable="true"
              :format="'yyyy'"
            >
            </datePicker>
            </v-col>
            <v-col cols="4" style="padding-left:0px; padding-right:0px;">
              <p style="color: grey; margin-left:12px;">Année du plan de l'année</p>
              <datePicker style="margin-left:12px;" 
              :placeholder="anneePlan"
              v-model="anneePlanFull"
               @changedYear="handleChangedYearPlan"
              :minimum-view="'year'"
              :maximum-view="'year'"
               :typeable="true"
              :format="'yyyy'"
            >
            </datePicker>

            </v-col>

            <v-col cols="4"  style="padding-left:0px; padding-right:0px;">
              <p style="color: grey; margin-left:12px;">Année du cycle de 9 ans</p>
              <datePicker 
              :placeholder="anneeCycle"
              v-model="anneeCycleFull"
               @changedYear="handleChangedYearCycle"
              :minimum-view="'year'"
              :maximum-view="'year'"
               :typeable="true"
              :format="'yyyy'"
            >
            </datePicker>
           

          
            </v-col>
                   <v-col cols="4" style="padding-top:0px;padding-bottom:0px" >
                <v-checkbox label="Page de garde" v-model="includePageGarde"></v-checkbox></v-col>
                             <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Thème Complet" v-model="includeRecapTheme"></v-checkbox></v-col> 
                  <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
                <v-checkbox label="Cycle de 9ans" v-model="includeCycle"></v-checkbox></v-col>
                  <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Calendrier" v-model="includeCalendrier"></v-checkbox></v-col> 
                  <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Plan de l'année" v-model="includePlan"></v-checkbox></v-col>
                   <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
                <v-checkbox label="Double Spirale " v-model="includeFullDoubleSpiral"></v-checkbox></v-col>
                 <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Spirale d'involution" v-model="includeDoubleSpiral"></v-checkbox></v-col> 
              <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Spirale d'évolution" v-model="includeDoubleSpiralDroite"></v-checkbox></v-col>
                <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Plan d'expression" v-model="includePlanExpression"></v-checkbox></v-col>
                   <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
              <v-checkbox label="Cycle d'accomplissement" v-model="includeCycleNaissance"></v-checkbox>    </v-col>
              <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" >
         
              <v-checkbox label="Champ des expériences" v-model="includeChampExperiences"></v-checkbox></v-col>
    <v-col cols="4"  style="padding-top:0px;padding-bottom:0px" > <v-checkbox label="Plan de vie" v-model="includePlanVie"></v-checkbox>
            </v-col>
     
    
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showConfig=false;SaveConfig()">Valider</v-btn>
            
            <v-spacer></v-spacer>
         
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Etes vous sure ? 
        </v-card-title>
        <v-card-text>Êtes-vous sûr de vouloir supprimer ce client ?
           Attention, cette opération est irréversible.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-form>
          <v-btn color="blue darken-1"  type="submit" text @click="closeDelete">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteConfirm">Valider</v-btn>
        </v-form>
          <v-spacer></v-spacer>
       
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteNotes" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
        >Etes vous sure ? 
      </v-card-title>
      <v-card-text>Êtes-vous sûr de vouloir supprimer cette note ?
         Attention, cette opération est irréversible.</v-card-text>
       
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1"  type="submit" text @click="closeDeleteNote">Annuler</v-btn>
          <v-btn color="blue darken-1" text @click="deleteNote(currentNoteId)">Valider</v-btn>
          <v-spacer></v-spacer>
       
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="detailNotes" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Note
        </v-card-title>
        <v-text-field
        v-model="datenote"
        label="Date"
        type="date"
        hide-details
        :rules="requiredRules"
        required
      ></v-text-field>
      <QuillEditor   style="height: 250px"  theme="snow"  contentType="html"
        :options="editorOption" label="Notes" v-model:content="newnotes"/> 
    
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1"  type="submit" text @click="detailNotes=false;">Annuler</v-btn>
          <v-btn v-if="currentNoteId" color="blue darken-1" text @click="dialogDeleteNotes=true;">Supprimer</v-btn>
          <v-btn v-if="currentNoteId" color="blue darken-1" text @click="editNote">Valider</v-btn>
          <v-btn v-else color="blue darken-1" text @click="addnote">Valider</v-btn>
          <v-spacer></v-spacer>
       
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showConfirm" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">
         Enregistrer.
        </v-card-title>
        <v-card-text>Les modifications ont bien été sauvegardées.</v-card-text>
        <v-card-actions>
        
      
          <v-btn color="blue darken-1" text @click="showConfirm=false">Ok</v-btn>
          <v-spacer></v-spacer>
       
        </v-card-actions>
      </v-card>
    </v-dialog>
   
  </v-row>

</v-container>
</template>

<script>
import router from "@/router";
import clientService from "../services/clientService";
import etudeService from "../services/etudeService";
import ThemeChampExperience from '../components/ThemeChampExperience.vue';
import ThemePlanExpression from "../components/ThemePlanExpression.vue";
import ThemeDoubleSpiral from "../components/ThemeDoubleSpiral.vue";
import ThemeDoubleSpiralDroite from "../components/ThemeDoubleSpiralDroite.vue";
import ThemeCycle from "../components/ThemeCycle.vue";
import ThemePlan from "../components/ThemePlan.vue";
import EtudeDetailComponent from "../components/EtudeDetailComponent.vue";
import CalendrierComponent from '../components/CalendrierComponent.vue';
import CycleComponent from '../components/CycleComponent.vue';
import PlanComponent from '../components/PlanComponent.vue';
import JourEnergieComponent from '../components/JourEnergieComponent.vue';
import { QuillEditor,Quill } from '@vueup/vue-quill'
  import '@vueup/vue-quill/dist/vue-quill.snow.css';
  import imageCompressor from 'quill-image-compress'
  import configService from '../services/configService';
  import Datepicker from 'vuejs3-datepicker';
  Quill.register("modules/imageCompressor", imageCompressor);
export default {
  data() {
    return {
      computedThemeHeight:'height:75dvh;',
      detailNotes: false,
      showConfig: false,
      showConfirm: false,
      showDelete: false,
      includePlanVie: false,
            includeCycleNaissance: false,
            includeChampExperiences: false,
            includePlanExpression: false,
            includeDoubleSpiral: false,
            includeDoubleSpiralDroite: false,
            includeFullDoubleSpiral:false,
            includePlan: false,
            includeCycle: false,
            includeCalendrier: false,
            includeRecapTheme: false,
            includePageGarde: false,
            includeCommentaires: true,
            anneeCalendrier: (new Date()).toISOString().substring(0,4),
            anneePlan: (new Date()).toISOString().substring(0,4),
            anneeCycle: (new Date()).toISOString().substring(0,4),
            anneeCalendrierFull: (new Date()).toISOString().substring(0,4),
            anneePlanFull: (new Date()).toISOString().substring(0,4),
            anneeCycleFull: (new Date()).toISOString().substring(0,4),
      newnotes:"",
      editorOption: configService.getQuillConfig(),
        menu:false,
      datenote:null,
      notes:[],
      logo: null,
      logoPath: "",
      dialog: false,
      champExperiences: {
    "1": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "2": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "3": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "4": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "5": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "6": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "7": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "8": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    },
    "9": {
        "valeur": "0",
        "couleur": "noire",
        "detail": null
    }
},
      planExpression: {
    "cérébrale": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "physique": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "émotif": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "Intuitif": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    }
},
      doubleSpiral : {
    "1": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "2": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "3": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "4": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "5": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    }
},
      cycleNaissance : {
    "1": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "2": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "3": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "4": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    },
    "5": {
        "valeur": "0",
        "couleur": "noir",
        "detail": null
    }
},
      planVie: {
    "Mission": {
        "Mission": {
            "valeur": "0",
            "couleur": "noir",
            "detail": null
        }
    },
    "Cycles": {
        "1er cycle": {
            "valeur": "0",
            "couleur": "noir",
            "detail": null
        },
        "2e cycle": {
            "valeur": "0",
            "couleur": "noir",
            "detail": "1999 - 27ans"
        },
        "3e cycle": {
            "valeur": "0",
            "couleur": "noir",
            "detail": "2026 - 54ans"
        }
    },
    "Actions": {
        "1ère action": {
            "valeur": "0",
            "couleur": "noir",
            "detail": "2006 - 34ans"
        },
        "2ème action": {
            "valeur": "0",
            "couleur": "noir",
            "detail": "2015 - 43ans"
        },
        "3ème action": {
            "valeur": "0",
            "couleur": "noir",
            "detail": "2024 - 52ans"
        },
        "4ème action": {
            "valeur": "0",
            "couleur": "noir",
            "detail": null
        }
    }
},
currentNoteId:null,
crtEtude:{id:''},
anneeEtude:(new Date()).toISOString().substring(0,4),
dateetude:(new Date()).toISOString().substring(0,10),
crtdateetude:(new Date()).toISOString().substring(0,10),
      tab: "Informations",
      etudes:[],
      anneePersonnelle:"",
            jourPersonnel:"",
            moisPersonnel:"",
      dialogDelete: false,
      dialogDeleteNotes: false,
      menu2: false,
      valid: false,
      overlay: false,
      refsitems: [
        'Informations',
        'Notes',
        'Thème',
      ],
      items: [
        'Informations',
        'Notes',
        'Thème',
      
        'Calendrier',
        'Cycle 9 ans',
        "Plan de l'année",
        'Créer une étude',
      ],
      refmore: [
       `Champ des expériences`,

      `La double spirale`,
       `Le cycle d'accomplissement`,
       `Le plan de vie`
      ],
      more: [
       `Champ des expériences`,
     
      `La double spirale`,
       `Le cycle d'accomplissement`,
       `Le plan de vie`
      ],
    
      client: {    
        prenoms: ["","","","","","",], id: "",  nom: "",nom_naissance: "", mail: "", telephone:"" },
        requiredRules: [
        value => {
          if (value) return true

          return ''
        }
      ],

      emailRules: [
      (value) => {
          if (!value) return true;

          if (/.+@.+\..+/.test(value)) return true;

          return "E-mail must be valid.";
        },
      ],
    };
  },
components: {
  ThemeChampExperience,
  ThemePlanExpression,
  ThemeDoubleSpiral,
  ThemeDoubleSpiralDroite,
  ThemeCycle,
  ThemePlan,
  EtudeDetailComponent,
  PlanComponent,
  CycleComponent,
  CalendrierComponent,
  JourEnergieComponent,
  QuillEditor,
   DatePicker:Datepicker

},
  emits: ["updatedClients"],
  mounted() {
    this.onResize()
   if(this.$route.params.id)
      this.open(this.$route.params.id)
  },
   methods: {
    onResize() {
          this.computedThemeHeight= "height:"+(window.innerHeight - 220)+'px;';
    },
    deleteCurentEtude(){

      etudeService.delete(  this.client.id,this.crtEtude.id).then(() => {  
        etudeService.getAll(this.client.id).then((result) => {
             
                this.etudes = result.data;
              })})    
    },
    print(){// Get HTML to print from element
//const prtHtml = document.getElementById('printableZone').innerHTML+ ' ';
const prtHtmlHeader = document.getElementsByClassName('toPrintHeader') && document.getElementsByClassName('toPrintHeader').length>0?document.getElementsByClassName('toPrintHeader')[0].innerHTML+ ' ':'';
const prtHtmlFooter = document.getElementsByClassName('toPrintFooter') && document.getElementsByClassName('toPrintFooter').length>0?document.getElementsByClassName('toPrintFooter')[0].innerHTML+ ' ':'';
const prtHtmlHeaderLandscape = document.getElementsByClassName('toPrintHeaderLandscape') && document.getElementsByClassName('toPrintHeaderLandscape').length>0?document.getElementsByClassName('toPrintHeaderLandscape')[0].innerHTML+ ' ':'';
const prtHtmlFooterLandscape = document.getElementsByClassName('toPrintFooterLandscape') && document.getElementsByClassName('toPrintFooterLandscape').length>0?document.getElementsByClassName('toPrintFooterLandscape')[0].innerHTML+ ' ':'';
const prtHtmlGarde =document.getElementsByClassName('toPrintGuard') && document.getElementsByClassName('toPrintGuard').length>0? document.getElementsByClassName('toPrintGuard')[0].innerHTML+ ' ':'';
const prtHtmls = document.getElementsByClassName('toPrint');

var prtHtmlThemePrint = ''
var prtHtmlThemePrintCom = ''
var prtHtmlCalendier=''
var prtHtml9ans=''
var prtHtmlPlan=''
var prtHtmlcycleneufprintHtmlcomm=''
var prtHtmlplanprintHtmlcomm=''
var prtHtmlplanViePrint
var prtHtmldoubleSpriralPrint
var prtHtmlspriralGauchePrint
var prtHtmlspriralDroitePrint
var prtHtmlcycleViePrint
var prtHtmlchampExperiencePrint
var prtHtmlplanExpressionPrint
var prtHtmlThemePrintIsLast=false;
var prtHtmlThemePrintComIsLast=false;
var prtHtml9ansIsLast=false;
var prtHtmlcycleneufprintHtmlcommIsLast=false;
var prtHtmlCalendierIsLast=false;
var prtHtmlPlanIsLast=false;
var prtHtmlplanprintHtmlcommIsLast=false;
var prtHtmlplanViePrintIsLast=false;
var prtHtmldoubleSpriralPrintIsLast=false;
var prtHtmlspriralDroitePrintIsLast=false;
var prtHtmlspriralGauchePrintIsLast=false;
var prtHtmlcycleViePrintIsLast=false;
var prtHtmlchampExperiencePrintIsLast=false;
var prtHtmlplanExpressionPrintIsLast=false;
if(prtHtmls && prtHtmls.length>0)
for (var k in prtHtmls) {

    // prtHtml+='<div class="pagebreak">'+prtHtmls[k].innerHTML +'</div>';
    if(prtHtmls[k].innerHTML)
    {
        if( prtHtmls[k].id=="themePrint")
        { prtHtmlThemePrint=prtHtmls[k].innerHTML;}

        if( prtHtmls[k].id=="themePrintCom")
        { prtHtmlThemePrintCom=prtHtmls[k].innerHTML;}    

        if( prtHtmls[k].id=="cycle9ansprint")
          {prtHtml9ans="<br/><br/><br/>"+prtHtmls[k].innerHTML;}
        if( prtHtmls[k].id=="cycleneufprintcomm")
          {prtHtmlcycleneufprintHtmlcomm=prtHtmls[k].innerHTML;}

          if( prtHtmls[k].id=="calendrierprint")
         {prtHtmlCalendier="<br/><br/><br/>"+prtHtmls[k].innerHTML;} 

          if( prtHtmls[k].id=="planprint")
          {prtHtmlPlan=prtHtmls[k].innerHTML;}
          if( prtHtmls[k].id=="planprintcomm")
          {prtHtmlplanprintHtmlcomm=prtHtmls[k].innerHTML;}

          if( prtHtmls[k].id=="planViePrint")
          {prtHtmlplanViePrint=prtHtmls[k].innerHTML;}
            
          if( prtHtmls[k].id=="doubleSpriralPrint")
          {prtHtmldoubleSpriralPrint=prtHtmls[k].innerHTML;}

           if( prtHtmls[k].id=="spriralGauchePrint")
          {prtHtmlspriralGauchePrint=prtHtmls[k].innerHTML;}
          
          if( prtHtmls[k].id=="spriralDroitePrint")
          {prtHtmlspriralDroitePrint=prtHtmls[k].innerHTML;}

          if( prtHtmls[k].id=="cycleViePrint")
          {prtHtmlcycleViePrint=prtHtmls[k].innerHTML;}

          if( prtHtmls[k].id=="champExperiencePrint")
          {prtHtmlchampExperiencePrint=prtHtmls[k].innerHTML;}

          if( prtHtmls[k].id=="planExpressionPrint")
          {prtHtmlplanExpressionPrint=prtHtmls[k].innerHTML;}

  }}
var htmlGarde='';
if(prtHtmlGarde)htmlGarde=`<div style="min-height: 1300px; break-after: always;page-break-after: always; margin: auto;">  ${prtHtmlGarde} </div>`
// Get all stylesheets HTML
let stylesHtml =document.head.innerHTML;
var findLast=false;
if (prtHtmlplanExpressionPrint) prtHtmlplanExpressionPrintIsLast=true;
  findLast =  findLast || prtHtmlplanExpressionPrintIsLast;
 prtHtmlchampExperiencePrintIsLast=!findLast && !prtHtmlplanExpressionPrintIsLast && !!(prtHtmlchampExperiencePrint);
 findLast =  findLast || prtHtmlchampExperiencePrintIsLast;
 prtHtmlcycleViePrintIsLast=!findLast &&  !prtHtmlchampExperiencePrintIsLast && !!(prtHtmlcycleViePrint);
  findLast =  findLast || prtHtmlcycleViePrintIsLast;
 prtHtmlspriralGauchePrintIsLast=!findLast &&  !prtHtmlcycleViePrintIsLast && !!(prtHtmlspriralGauchePrint);
  findLast =  findLast || prtHtmlspriralGauchePrintIsLast;
 prtHtmlspriralDroitePrintIsLast  =!findLast &&  !prtHtmlspriralGauchePrintIsLast && !!(prtHtmlspriralDroitePrint);
  findLast =  findLast || prtHtmlspriralDroitePrintIsLast;

 prtHtmldoubleSpriralPrintIsLast=!findLast &&  !prtHtmlspriralDroitePrintIsLast && !!(prtHtmldoubleSpriralPrint);
  findLast =  findLast || prtHtmldoubleSpriralPrintIsLast;
 prtHtmlplanViePrintIsLast=!findLast &&  !prtHtmldoubleSpriralPrintIsLast && !!(prtHtmlplanViePrint);
  findLast =  findLast || prtHtmlplanViePrintIsLast;
 prtHtmlplanprintHtmlcommIsLast= !findLast && !prtHtmlplanViePrintIsLast && !!(prtHtmlplanprintHtmlcomm);
  findLast =  findLast || prtHtmlplanprintHtmlcommIsLast;
 prtHtmlPlanIsLast=!findLast &&  !prtHtmlplanprintHtmlcommIsLast && !!(prtHtmlPlan);
  findLast =  findLast || prtHtmlPlanIsLast;
 prtHtmlCalendierIsLast=!findLast &&  !prtHtmlPlanIsLast && !!(prtHtmlCalendier);
  findLast =  findLast || prtHtmlCalendierIsLast;

 prtHtmlcycleneufprintHtmlcommIsLast=!findLast &&  !prtHtmlCalendierIsLast && !!(prtHtmlcycleneufprintHtmlcomm);
  findLast =  findLast || prtHtmlcycleneufprintHtmlcommIsLast;

 prtHtml9ansIsLast=!findLast &&  !prtHtmlcycleneufprintHtmlcommIsLast && !!(prtHtml9ans);
  findLast =  findLast || prtHtml9ansIsLast;

 prtHtmlThemePrintComIsLast=!findLast &&  !prtHtml9ansIsLast && !!(prtHtmlThemePrintCom);
  findLast =  findLast || prtHtmlThemePrintComIsLast;

 prtHtmlThemePrintIsLast= !findLast && !prtHtmlThemePrintComIsLast && !!(prtHtmlThemePrint);
  findLast =  findLast || prtHtmlThemePrintIsLast;

const WinPrint = window.open('', '', 'height=1500px;width=1500px;left=0,top=0,toolbar=0,scrollbars=0,status=0');

WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
 <style>  .calendar {
    top: 0px!important;;
  }
  .border {
    border-style: none !important;
    box-shadow: none!important;}
  </style>
  <style type="text/css" media="print">
@page {
    margin: 0px; 
    margin-right: 20px;
    margin-bottom:10px /* this affects the margin in the printer settings */
}
</style>
  </head>



 ${this.getScriptResize()}

  <body style="margin-left: 20px,margin-top: 10px" onload=" document.title = '${this.getDefaultFileName()}';resizepage();print()">

 
      ${htmlGarde}
      ${this.putInTable(prtHtmlThemePrint,prtHtmlHeader,prtHtmlFooter,prtHtmlThemePrintIsLast )}
      ${this.putInTable(prtHtmlThemePrintCom,prtHtmlHeader,prtHtmlFooter,prtHtmlThemePrintComIsLast )}
      ${this.putInLandscapeTable(prtHtml9ans,prtHtmlHeaderLandscape,prtHtmlFooterLandscape,prtHtml9ansIsLast)}
      ${this.putInTable(prtHtmlcycleneufprintHtmlcomm,prtHtmlHeader,prtHtmlFooter,prtHtmlcycleneufprintHtmlcommIsLast)}
      ${this.putInLandscapeTable(prtHtmlCalendier,prtHtmlHeaderLandscape,prtHtmlFooterLandscape,prtHtmlCalendierIsLast)}
      ${this.putInLandscapeTable(prtHtmlPlan,prtHtmlHeaderLandscape,prtHtmlFooterLandscape,prtHtmlPlanIsLast)}
      ${this.putInTable(prtHtmlplanprintHtmlcomm,prtHtmlHeader,prtHtmlFooter,prtHtmlplanprintHtmlcommIsLast)}
      ${this.putInTable(prtHtmlplanViePrint,prtHtmlHeader,prtHtmlFooter,prtHtmlplanViePrintIsLast)}    
      ${this.putInTable(prtHtmldoubleSpriralPrint,prtHtmlHeader,prtHtmlFooter,prtHtmldoubleSpriralPrintIsLast)}
      ${this.putInTable(prtHtmlspriralDroitePrint,prtHtmlHeader,prtHtmlFooter,prtHtmlspriralDroitePrintIsLast)}
      ${this.putInTable(prtHtmlspriralGauchePrint,prtHtmlHeader,prtHtmlFooter,prtHtmlspriralGauchePrintIsLast)}
      ${this.putInTable(prtHtmlcycleViePrint,prtHtmlHeader,prtHtmlFooter,prtHtmlcycleViePrintIsLast)}
      ${this.putInTable(prtHtmlchampExperiencePrint,prtHtmlHeader,prtHtmlFooter,prtHtmlchampExperiencePrintIsLast)}  
      ${this.putInTable(prtHtmlplanExpressionPrint,prtHtmlHeader,prtHtmlFooter,true)}

        </body>
</html>`);

WinPrint.document.close();
WinPrint.focus();



},
getScriptResize() {

return  `<XXX> window.addEventListener("afterprint", (event) => {
  close();
});
function resizepage(){
              var elts = document.getElementsByClassName("toresize");
              
              let nodeArray=Array.from(elts);
              if(nodeArray)
              { 
                nodeArray.forEach((element) => 
                  {
                 if(element.offsetHeight>1100){
                    var lastsize = (element.offsetHeight-1200) % 1100 ;
                    var papasize = element.parentElement.parentElement.parentElement.parentElement.offsetHeight ;
                  //  var decorationSize= papaSize - element.offsetHeight;
                 //   var trueLastsize = lastsize + decorationSize;
                    if(lastsize>0)
                    {
                    element.style.height = (element.offsetHeight + (1100 - lastsize)) + "px";
                  
                    }
    }
                  });
              }
            }
              </XXX>`.replaceAll("XXX","script");
            },

getDefaultFileName() {
  
  return this.client.nomNaissance.replace(/'/g,"")+'-'+this.dateetude.replace(/-/g,"")+'.pdf';
},
putInLandscapeTable(innerHTML,header,footer,isLast)
{
  var shouldBreak =!isLast?"break-before: always;page-break-before: always;":"break-before: always;page-break-before: always;";
if(innerHTML)
{
 return `
  <table style='margin-left:25px; margin-top: 0px;margin-bottom: 0px; padding-left:10px;padding-top:5px ;  size: A4 landscape; page: landscape; ${shouldBreak}    clear: both;  width:1250px; height:900px'>

  <!-- Body or content section -->
  <tbody >
      <tr style="height:120px"><td> ${header}</td></tr>
      <tr><td style="vertical-align: top;">${innerHTML}</td></tr>
      <tr style="height:20px"><td>${footer}</td></tr>
  </tbody>
</table> `
} else return ''},
putInTable(innerHTML,header,footer,isLast){
  var shouldBreak =  !isLast ? "break-after: always; page-break-after: always":"";
  if(innerHTML){
  return `<table style='margin-left:35px;padding-left:10px;    clear: both;;  width:800px;     ${shouldBreak} '>
  <!-- Header section -->
  <thead style="display: table-header-group;">
    <tr>
      <td>
        ${header}
      </td>
    </tr>
  </thead>
 <!-- Footer section -->
  <tfoot style="display: table-footer-group;">
    <tr>
      <td>
        ${footer}
      </td>
    </tr>
  </tfoot>
  <!-- Body or content section -->
  <tbody>
    <tr >
      <td>
       <div style="min-height:1100px;width:900px;margin-left:10px" class="toresize">${innerHTML}<div>

      </td>
    </tr>
  </tbody>
</table> `}
  else return ''},
   handleChangedYear(payload) {
    console.log(payload);
        this.anneeCalendrier = ""+payload.year
      },
      handleChangedYearPlan(payload) {
    
    this.anneePlan = ""+payload.year
  },
  handleChangedYearCycle(payload) {
    
    this.anneeCycle = ""+payload.year
  },
newEtude(){

  etudeService.save( this.client.id,{DateEtude: this.dateetude}).then((created) => {  
    etudeService.getAll(this.client.id).then((result) => {
            this.showConfirm=true;
            this.etudes = result.data;
            var etude = this.etudes.find((etude) => etude.id === created.data.id);
            this.crtdateetude=etude.dateEtude;
            this.crtEtude=etude;
            this.anneeCalendrier= (new Date(etude.dateEtude)).toISOString().substring(0,4);
            this.anneePlan= (new Date(etude.dateEtude)).toISOString().substring(0,4);
            this.anneeCycle= (new Date(etude.dateEtude)).toISOString().substring(0,4);
            this.anneeCalendrierFull=(new Date(etude.dateEtude)).toISOString();
            this.anneePlanFull=(new Date(etude.dateEtude)).toISOString();
            this.anneeCycleFull=(new Date(etude.dateEtude)).toISOString();
            this.menu=false;
          })})
},

back(){   router.push({ name: "clients" });},
    goto(tabname){
      this.tab = tabname;
    },
    convertDate(adate){
      return adate ? adate.substring(0,10) : "";  
     /* if(adate)
            return (new Date(adate)).getDay().toLocaleDateString('fr-FR') 
          return "";*/
        },
    supprimer() {
      this.dialogDelete = true;
    },
    ajouterNote() {
      this.currentNoteId = null;
      this.newnotes = ""; 
      this.detailNotes = true;
      this.datenote =new Date().toISOString("fr").substring(0,10);
    },
    ouvrir(noteId) {
      this.currentNoteId = noteId;
      this.detailNotes = true;
      this.newnotes = this.notes.find((note) => note.id === noteId).content;
        this.datenote = this.notes.find((note) => note.id === noteId).createdAt.substring(0,10);
    },
    openConfig(){
    this.showConfig=true
  },
    async SaveConfig(){
    
etudeService.save( this.client.id,{DateEtude: this.crtEtude.dateEtude,
  Id: this.crtEtude.id,
 AnneeCalendrier: this.anneeCalendrier,
 AnneeCycle: this.anneeCycle,
 AnneePlan: this.anneePlan,
   IncludePlanVie :this.  includePlanVie,
   IncludeCycleNaissance  :this.includeCycleNaissance,
   IncludeChampExperiences  :this.includeChampExperiences,
   IncludePlanExpression:this.includePlanExpression,
   IncludeDoubleSpiral :this.includeDoubleSpiral,
   IncludeDoubleSpiralDroite:this.includeDoubleSpiralDroite,
   IncludeFullDoubleSpiral:this.includeFullDoubleSpiral,
   IncludePlan  :this.includePlan,
   IncludeCycle :this.includeCycle,
   IncludeCalendrier :this.includeCalendrier,
   IncludeRecapTheme :this.includeRecapTheme,
   IncludePageGarde :this.includePageGarde,
 

}).then((created) => {  
  etudeService.getAll(this.client.id).then((result) => {
          this.showConfirm=true;
          this.etudes = result.data;
          var etude = this.etudes.find((etude) => etude.id === created.data.id);
          this.crtdateetude=etude.dateEtude;
          this.crtEtude=etude;
          this.anneeCalendrier= etude.anneeCalendrier? etude.anneeCalendrier: (new Date(etude.dateEtude)).toISOString().substring(0,4);
          this.anneePlan= etude.anneePlan? etude.anneePlan: (new Date(etude.dateEtude)).toISOString().substring(0,4);
          this.anneeCycle= etude.anneeCycle? etude.anneeCycle: (new Date(etude.dateEtude)).toISOString().substring(0,4);
          this.anneeCalendrierFull=(new Date(this.anneeCalendrier,1,1)).toISOString();
          this.anneePlanFull=(new Date( this.anneePlan,1,1)).toISOString();
          this.anneeCycleFull=(new Date(this.anneeCycle,1,1)).toISOString();
          this.menu=false;         
        })})


    },
    async  save() {
      if(!this.client.nomNaissance){this.client.nomNaissance=this.client.nom}
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        if (this.logo && this.logo.length > 0 && this.logo[0])
         clientService.saveLogo(this.client, this.logo[0]);
         clientService
          .save(this.client)
          .then((resutlt) => {
            this.client.id =resutlt.data.id;
            //this.dialog = false;
       
            this.showConfirm=true;
                 this.open(  this.client.id)
            this.$emit("updatedClients");
          })
          .catch((e) => {
            console.log("serveur KO");
            console.log(e);
          });
      }
    },
    getnotes(){   clientService.getNotes(this.client.id).then((result) => { 
    
  
      this.notes= result.data.sort((a, b) => a.createdAt - b.createdAt);

      this.notes.sort((a, b) => {
  const nameA = new Date(a.createdAt); // ignore upper and lowercase
  const nameB = new Date(b.createdAt); // ignore upper and lowercase
  if (nameA > nameB) {
    return -1;
  }
  if (nameA < nameB) {
    return 1;
  }

  // names must be equal
  return 0;
});
      
    
    })},
    showHelp(){
      //recuperer les styles aide
      //recuperer le contenu de l'aide
      const prtHtml = document.getElementById('aide').innerHTML;
      let stylesHtml =document.head.innerHTML;
      const WinPrint = window.open('', '', 'height=800px;width=600px;left=0,top=0,toolbar=0,scrollbars=0,status=0');

WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
 <style>  .calendar {
    top: 0px!important;;
  }
  .border {
    border-style: none !important;
    box-shadow: none!important;}
  </style>
  <style type="text/css" media="print">
@page {
    margin: 0px; 
    margin-bottom:10px /* this affects the margin in the printer settings */
}
</style>
  </head>
  <body style="margin-left: 30px;margin-top: 10px" onload=" document.title = 'Aide'">
  ${prtHtml}
    </body>
    </html>
    `);},
    deleteNote(idnote){     
      this.currentNoteId= null;
      this.dialogDeleteNotes = false;
      this.detailNotes=false;
       clientService.deleteNotes(this.client.id,idnote).then(() => {this.getnotes()})},
    editNote(){ 
      var noteToUpdate = {   
        id:this.currentNoteId,
        clientid :this.client.id,
        content :this.newnotes,
        CreatedAt:this.datenote
      }
      clientService.editNotes(this.client.id,noteToUpdate).then(() => {this.detailNotes=false; this.getnotes()})},
    addnote(){  
      clientService.addNotes(this.client.id, {   
          clientid :this.client.id,
          content :this.newnotes,
          CreatedAt:this.datenote
        }

          ).then(() => {this.detailNotes=false; 
            this.getnotes();
            this.newnotes=""
          })
  },
    addItem (item) {
      this.items=[]
      this.items.push(...this.refsitems)
        this.items.push(
          ...this.more.splice(this.more.indexOf(item), 1),
        )
        this.more=[]
        this.more.push(...this.refmore)
        this.$nextTick(() => { this.tab =  item })
      },

      closeDeleteNote() {
      this.dialogDeleteNotes = false;
      this.currentNoteId= null;
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    deleteConfirm() {
      this.dialogDelete = false;
      clientService
        .delete(this.client.id)
        .then(() => {
          this.dialog = false;
         this.$emit("updatedClients");
         router.push({ name: "clients" });
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
    },
    create() {
      this.dialog = true;
      this.logoPath = "";
      this.client = { prenoms: ["","","","","","",], id: "", role: "", nom: "", mail: "" };
    },
    open(adhId) {
 
      this.tab ="Informations";
      this.client = { prenoms: ["","","","","","",], id: "", role: "", nom: "", mail: "" };
      clientService
        .get(adhId)
        .then((resutlt) => {
          this.client = resutlt.data;
          this.client.naissance = this.convertDate(this.client.naissance);
          this.getnotes();
         etudeService.getAll(this.client.id).then((result) => {
       
            this.etudes = result.data;
          })
          clientService.getChampExperience(this.client).then((result) => {
          
            this.champExperiences = result.data;
          })

          clientService. getPlanExpression(this.client).then((result) => {
           
            this.planExpression = result.data;
          })
    clientService. getDoubleSpiral(this.client).then((result) => {
           
            this.doubleSpiral = result.data;
          })
    clientService. getCycleNaissance(this.client).then((result) => {
           
            this.cycleNaissance = result.data;
          })

          clientService.getPlanVie(this.client).then((result) => {
         
            this.planVie = result.data;
          })
          clientService. getAnneePersonnelle(this.client.id, this.client.naissance).then((resutlt) => {
      this.anneePersonnelle = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService. getMoisPersonnel(this.client.id, this.client.naissance).then((resutlt) => {
              this.moisPersonnel = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
    clientService. getJourPersonnel(this.client.id, this.client.naissance).then((resutlt) => {
              this.jourPersonnel = resutlt.data
          }) .catch((e) => {
            console.log("serveur KO");
        console.log(e);
       
    })
        })
        .catch((e) => {
          console.log("serveur KO");
          console.log(e);
        });
      this.dialog = true;
    },
  },
  computed:{
   annees(){
      let opts = [];
      for(let i = 1900; i <= 2050; i++){
          opts.push(i.toString());
      }

      return opts
   }
}
};
</script>

<style>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}
</style>
